import { DEFAULT_SPLASH_IMAGE } from "%/constants/publication-constants";

export const STORY_VARIANT_TYPES = [
  {
    label: "X",
    value: "x",
    description: "Variant for X",
    splash: DEFAULT_SPLASH_IMAGE,
  },
  {
    label: "LinkedIn",
    value: "linkedin",
    description: "Variant for LinkedIn",
    splash: DEFAULT_SPLASH_IMAGE,
  },
  {
    label: "Syndication Market",
    value: "syndication",
    description: "Variant for Syndication Market",
    splash: DEFAULT_SPLASH_IMAGE,
  },
  {
    label: "Another Publication",
    value: "publication",
    description: "Variant for Another Publication",
    splash: DEFAULT_SPLASH_IMAGE,
  },
];
