<template>
  <PageWrapper
    :active-section="SECTION_MONETIZATION"
    :active-page="PAGE_LINKS_REPORT"
    title="My Links"
    page-name="My Links"
    :breadcrumbs="breadcrumbs"
    :action-button-href="`/monetization/org/${orgId}/links/collections`"
    action-button-text="View Collections"
  >
    <iRow :width="2700" overflow="hidden">
      <SmartFeed :pinia-store="useAffiliateLinkFeedStore" default-smart-feed-layout="table" default-entity-layout="tableRow" />
    </iRow>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import { useAffiliateLinkStore } from "@/stores/affiliate-link-store";
import { mapActions, mapState, mapWritableState } from "pinia";
import SmartFeed from "SmartFeed/components/SmartFeed";
import { SECTION_MONETIZATION, PAGE_LINKS_REPORT } from "@/constants/nav-constants";
import { useAffiliateLinkFeedStore } from "SmartFeed/stores/feed/affiliate-link-feed-store";

export default {
  name: "LinksReport",
  components: {
    SmartFeed,
    PageWrapper,
    // SelectAffiliateCollection,
  },
  data() {
    return {
      SECTION_MONETIZATION,
      PAGE_LINKS_REPORT,
      editingDescription: false,
      editingBrand: false,
      editingProductName: false,
      affiliateLink: {},
      isAddToCollectionModalOpen: false,
      affiliateDropdownId: -1,
    };
  },
  computed: {
    ...mapWritableState(useAffiliateLinkStore, [
      "editingRowColumn",
      "editingValue",
      "editingRowKey",
    ]),
    ...mapState(useAffiliateLinkStore, [
      "orgId",
      "affiliateLinks",
      "loadingAffiliateLinks",
      "hasMoreAffiliateLinks",
    ]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/monetization/org/${this.orgId}`, label: "Monetization" },
      ];
    },
    fields() {
      return [
        {
          key: "affiliate_url",
          label: "Affiliate Url",
          width: "350px",
        },
        {
          key: "original_url",
          label: "Original Url",
          width: "350px",
        },
        {
          key: "advertiser",
          label: "Advertiser",
          width: "200px",
        },
        {
          key: "description",
          label: "My Description",
          width: this.editingDescription ? "350px" : undefined,
        },
        {
          key: "brand_name",
          label: "Brand Name",
          width: this.editingBrand ? "350px" : undefined,
        },
        {
          key: "product_name",
          label: "Product Name",
          width: this.editingProductName ? "350px" : undefined,
        },
        {
          key: "clicks",
          label: "Clicks",
        },
        {
          key: "sales",
          label: "Sales",
        },
        {
          key: "total_earnings",
          label: "Total Earnings",
        },
        {
          key: "pending_earnings",
          label: "Pending Earnings",
        },
        {
          key: "locked_earnings",
          label: "Locked Earnings",
        },
        {
          key: "actions",
          label: "",
        },
      ];
    },
  },
  async created() {
    this.fetchAffiliateLinksByOrgId();
  },
  methods: {
    useAffiliateLinkFeedStore,
    ...mapActions(useAffiliateLinkStore, [
      "editAffiliateLinkProductDetails",
      "fetchAffiliateLinksByOrgId",
    ]),
    openAddToCollectionModal(data) {
      this.affiliateLink = data;
      this.isAddToCollectionModalOpen = true;
      this.closeAffiliateDropdown();
    },
    closeAddToCollectionModal() {
      this.affiliateLink = {};
      this.isAddToCollectionModalOpen = false;
    },
    openAffiliateDropdown(id) {
      this.affiliateDropdownId = id;
    },
    closeAffiliateDropdown() {
      this.affiliateDropdownId = -1;
    },
    getTotalEarningsCellContent(data) {
      let currency = data.performance.locked_earnings.commission_currency;
      if (!currency) {
        currency = data.performance.pending_earnings.commission_currency;
      }

      let totalEarnings = 0.000;

      if (data.performance.locked_earnings.total_locked_earnings) {
        totalEarnings += data.performance.locked_earnings.total_locked_earnings;
      }
      if (data.performance.pending_earnings.total_pending_earnings) {
        totalEarnings += data.performance.pending_earnings.total_pending_earnings;
      }
      return this.getFormattedCurrencyValue(currency, totalEarnings);
    },
    getFormattedCurrencyValue(currency, value) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: currency,
      }).format(value);
    },
  },
};
</script>
