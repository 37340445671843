<template>
  <iModal
    :width="800"
    max-width-only
    :visible="visible"
    title="Deploy Publication To Production"
    primary-action-button-label="Deploy"
    :primary-action-button-loading="loadingPublishConfigToProduction"
    @close="closeModal"
    @click:primary="publishCurrentConfig"
    @click:secondary="closeModal"
  >
    <template #body>
      <iTextArea
        v-model="memo"
        label="Notes"
        placeholder="Deploy Latest Staging Changes to Production..."
      />
    </template>
  </iModal>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useConfigurationStore } from "@/stores/configuration-store";

export default {
  name: "PublicationDeploymentConfirmationModal",
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["close"],
  data() {
    return {
      memo: "",
    };
  },
  computed: {
    ...mapState(useConfigurationStore, [
      "loadingPublishConfigToProduction",
    ]),
  },
  methods: {
    ...mapActions(useConfigurationStore, ["publishConfigToProduction"] ),
    closeModal(event) {
      this.$emit("close", event);
    },
    publishCurrentConfig() {
      this.publishConfigToProduction(this.memo, this.closeModal);
    },
  },
};
</script>
