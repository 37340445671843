import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";
import { usePublicationStore } from "@/stores/publication-store";

export const useFeedGroupFeedStore = defineSmartFeedPiniaStore("feed-group-feed-store", {
  state: () => ({
    smartFeedEngine: new SmartFeedEngine(),
    feed: [],
    metadata: {
      hasMore: true,
      lastId: 0,
      publicationId: usePublicationStore().publicationId,
    },
    isLoading: false,
  }),
  getters: {
    hasMore() {
      return this.metadata.hasMore;
    },
  },
  actions: {
    reload() {
      this.$reset();
      this.getSmartFeed();
    },
    getSmartFeed() {
      this.apiGet(
        `/feeds/publication/${this.metadata.publicationId}/feed-groups/smart-feed`,
        {
          last_id: this.metadata.lastId,
        },
        data => this.handleSmartFeedResponse(data),
      );
    },
  },
});
