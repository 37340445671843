<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_PUBLICATION_SETTINGS"
    title="Settings"
    :breadcrumbs="breadcrumbs"
    page-name="Settings"
    align="left"
    width="full"
  />
</template>

<script>
import { mapState } from "pinia";
import PageWrapper from "@/components/PageWrapper.vue";
import { PAGE_PUBLICATION_SETTINGS, SECTION_PUBLICATIONS } from "@/constants/nav-constants";
import { usePublicationStore } from "@/stores/publication-store";

export default {
  name: "PublicationSettings",
  components: {
    PageWrapper,
  },
  data() {
    return {
      SECTION_PUBLICATIONS,
      PAGE_PUBLICATION_SETTINGS,
    };
  },
  computed: {
    ...mapState(usePublicationStore, [
      "orgId",
    ]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
      ];
    },
  },
};
</script>
