import { links } from "%/enums/link-enums";
import { useGeneralStore } from "%/stores/general-store";
import SmartFeedEngine from "SmartFeed/smart-feed-engine";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";
import { usePublicationStore } from "@/stores/publication-store";

export const usePublicationUsersFeedStore = defineSmartFeedPiniaStore("publication-users-feed-store", {
  state: () => ({
    smartFeedEngine: new SmartFeedEngine(),
    feed: [],
    metadata: {
      hasMore: true,
      lastId: 0,
      publicationId: usePublicationStore().publicationId,
    },
    tableFields: [
      {
        key: "avatarUrl",
        label: "Avatar",
      },
      {
        key: "firstName",
        label: "Name",
      },
      {
        key: "emailAddress",
        label: "Email",
      },
      {
        key: "status",
        label: "Status",
      },
      {
        key: "dateCreated",
        label: "Created At",
      },
    ],
    isLoading: false,
  }),
  getters: {
    apiV1() {
      return useGeneralStore().getLink(links.api);
    },
    hasMore() {
      return this.metadata.hasMore;
    },
  },
  actions: {
    getSmartFeed() {
      this.apiGet(
        `${this.apiV1}/publication/users/${this.metadata.publicationId}/smart-feed`,
        {
          last_id: this.metadata.lastId,
        },
        data => this.handleSmartFeedResponse(data),
        () => {
          // prevent infinite errors
          this.metadata.hasMore = false;
        },
      );
    },
  },
});
