// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile-display[data-v-2a962380] {
  display: none;
}
@media (max-width: 768px) {
.mobile-display[data-v-2a962380] {
    display: block;
}
}
.not-mobile-display[data-v-2a962380] {
  display: block;
}
@media (max-width: 768px) {
.not-mobile-display[data-v-2a962380] {
    display: none;
}
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/pages/community/OrgProfilePage.vue","webpack://./OrgProfilePage.vue","webpack://./node_modules/@bloglovin/vue-component-library/dist/breakpoint-mixin.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;ACDF;ACDE;AFCF;IAGI,cAAA;ACCF;AACF;ADEA;EACE,cAAA;ACCF;ACVE;AFQF;IAGI,aAAA;ACGF;AACF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.mobile-display {\n  display: none;\n  @include phone {\n    display: block;\n  }\n}\n\n.not-mobile-display {\n  display: block;\n  @include phone {\n    display: none;\n  }\n}\n",".mobile-display {\n  display: none;\n}\n@media (max-width: 768px) {\n  .mobile-display {\n    display: block;\n  }\n}\n\n.not-mobile-display {\n  display: block;\n}\n@media (max-width: 768px) {\n  .not-mobile-display {\n    display: none;\n  }\n}","@mixin phone {\n  @media (max-width: 768px) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media (max-width: 1365px) {\n    @content;\n  }\n}\n\n@mixin desktop {\n  @media (min-width: 1366px) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
