<template>
  <iHeading> Post Creation </iHeading>
  <iSelect
    :model-value="ugcInterface"
    return-value
    :items="validUgcTypes"
    label="Post Creation Type"
    value-field="value"
    @update:model-value="updateUgcInterfaceConfiguration"
  />
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useConfigurationStore } from "@/stores/configuration-store";

export default {
  name: "PublicationPostCreation",
  data() {
    return {
      postTypes: [
        { name: "Quick Post", value: "quick_post" },
      ],
    };
  },
  computed: {
    ...mapState(useConfigurationStore, [
      "ugcInterface",
    ]),
    validUgcTypes() {
      return [
        { name: "None", value: "none" },
        { name: "Article", value: "article" },
        { name: "Multimedia", value: "multimedia" },
        { name: "Quick Post", value: "quickPost" },
      ];
    },
  },
  methods: {
    ...mapActions(useConfigurationStore, ["updateConfiguration"]),
    updateUgcInterfaceConfiguration(ugcType) {
      this.updateConfiguration({ ugcInterface: ugcType });
    },
  },
};
</script>

<style scoped>

</style>
