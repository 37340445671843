<template>
  <iColumn class="message-container" gap="none">
    <ConversationHeader
      :author-avatar="authorAvatar"
      :author-id="authorId"
      :author-name="getAuthorName"
      :date="dateSent"
      :is-moderator="isModerator"
      :moderation-enabled="moderationEnabled"
    >
      <template #content>
        <MessageFlags
          v-if="isAuthor"
          :moderation-status="moderationStatus"
          :status="status"
          :timestamp-updated="dateUpdated"
        />
        <iRow v-if="isEditing || isEditSaving">
          <iColumn>
            <iTextInput
              v-model="commentEdit"
              name="editComment"
              width="fill"
              @enter="saveEdit"
            />
            <iRow align="right" vertical-align="middle">
              <iColumn width="hug">
                <iButton variant="tertiary" @click="cancelEditing">
                  Cancel
                </iButton>
              </iColumn>
              <iColumn width="hug">
                <iButton :is-loading="isEditSaving" loading-spinner-position="inside" @click="saveEdit">
                  Save
                </iButton>
              </iColumn>
            </iRow>
          </iColumn>
        </iRow>
        <template v-else>
          <iRow>
            <!-- eslint-disable-next-line -->
            <iText class="message-content" v-html="message"/>
          </iRow>
          <iText v-if="showAuthorPendingMessage" variant="subtle">
            Not visible, pending moderation.
          </iText>
          <iText v-if="showAuthorBlockedMessage" variant="subtle">
            This message has been blocked.
          </iText>
          <iText v-if="isMessageLoading[messageId]?.error" variant="subtle">
            {{ isMessageLoading[messageId].error }}
          </iText>
          <iRow v-if="isModerator && moderationEnabled" align="between">
            <iText variant="accent">
              Report Count: {{ numReports }}
            </iText>
            <iText variant="accent">
              moderation score: {{ moderationScore }}
            </iText>
          </iRow>
          <ConversationActions
            v-if="!isEditing"
            :author-id="authorId"
            :current-message="message"
            :message-id="messageId"
            :moderation-status="moderationStatus"
            :num-likes="numLikes"
            :num-replies="numApprovedReplies"
            :status="status"
            :user-down-voted="userDownVoted"
            :user-liked="userLiked"
            :user-up-voted="userUpVoted"
            :vote-score="voteScore"
            :can-react="canReact"
            @approve="approve"
            @block="block"
            @delete="deleteComment"
            @downvote="downVote"
            @escalate="escalate"
            @like="like"
            @reply="turnOnIsReplying"
            @report="report"
            @revokedownvote="revokeDownVote"
            @revokeupvote="revokeUpVote"
            @undelete="unDeleteComment"
            @unlike="unlike"
            @upvote="upVote"
            @start-editing="turnOnIsEditing"
            @reaction-selected="reactionSelected"
          />
          <ConversationFooter
            :author-id="authorId"
            :message-id="messageId"
            :num-replies="numApprovedReplies"
            :reactions="reactions"
            :status="status"
            @reaction-selected="reactionSelected"
            @reply="turnOnIsReplying"
          />
        </template>
      </template>
    </ConversationHeader>
  </iColumn>
  <iModal
    v-if="isReplying"
    :width="600"
    scrollable
    title="Reply in thread"
    @close="isReplying = false"
  >
    <template #body>
      <iColumn gap="none">
        <iColumn class="message-container-preview" gap="small">
          <ConversationHeader
            :author-avatar="authorAvatar"
            :author-id="authorId"
            :author-name="getAuthorName"
            :date="dateSent"
            :is-moderator="isModerator"
            :moderation-enabled="moderationEnabled"
          >
            <template #content>
              <MessageFlags
                v-if="isAuthor"
                :moderation-status="moderationStatus"
                :status="status"
                :timestamp-updated="dateUpdated"
              />
              <iRow>
                <!-- eslint-disable-next-line -->
                <iText class="message-content" v-html="message"/>
              </iRow>
              <iText v-if="showAuthorPendingMessage" variant="subtle">
                Not visible, pending moderation.
              </iText>
              <iText v-if="showAuthorBlockedMessage" variant="subtle">
                This message has been blocked.
              </iText>
              <iText v-if="isMessageLoading[messageId]?.error" variant="subtle">
                {{ isMessageLoading[messageId].error }}
              </iText>
              <iRow v-if="isModerator && moderationEnabled" align="between">
                <iText variant="accent">
                  Report Count: {{ numReports }}
                </iText>
                <iText variant="accent">
                  moderation score: {{ moderationScore }}
                </iText>
              </iRow>
            </template>
          </ConversationHeader>
          <iRow vertical-align="middle" wrap="nowrap">
            <iColumn width="hug">
              <iText
                v-if="replies.length > 0"
                :wrap="false"
                font-size="small"
                variant="subtle"
              >
                {{ replies.length }} {{ replies.length === 1 ? "Reply" : "Replies" }}
              </iText>
            </iColumn>
            <iSpace class="divider" width="fill" />
          </iRow>
        </iColumn>
        <ReplyWidget
          :conversation-id="conversationId"
          :message="message"
          :message-id="messageId"
          :replies="replies"
        />
      </iColumn>
    </template>
    <template #footer>
      <iColumn
        :width="600"
        class="reply-editor"
        gap="extraSmall"
        max-width-only
      >
        <NewReply
          :conversation-id="conversationId"
          :entity-id="messageId"
          context="reply"
        />
      </iColumn>
    </template>
  </iModal>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useConversationStore } from "%/stores/conversation-store";
import ConversationActions from "%/components/community/chatrooms/slack-style/ConversationActions";
import ConversationHeader from "%/components/community/chatrooms/slack-style/ConversationHeader";
import MessageFlags from "%/components/community/chatrooms/slack-style/MessageFlags";
import ReplyWidget from "%/components/community/chatrooms/slack-style/ReplyWidget";
import { Enum } from "@bloglovin/vue-component-library";
import { DEFAULT_USER_AVATAR } from "%/constants/user-constants";
import { useGeneralStore } from "%/stores/general-store";
import ConversationFooter from "%/components/community/chatrooms/slack-style/ConversationFooter";
import NewReply from "%/components/community/chatrooms/slack-style/NewReply";

export default {
  name: "ActiveCommentWidget",
  components: {
    NewReply,
    ConversationFooter,
    ConversationHeader,
    ConversationActions,
    MessageFlags,
    ReplyWidget,
  },
  props: {
    width: {
      type: [Enum, Number],
      required: false,
      default: 500,
      options: ["fill", "hug"],
    },
    message: {
      type: String,
      required: true,
    },
    messageId: {
      type: Number,
      required: true,
    },
    conversationId: {
      type: Number,
      required: true,
    },
    dateSent: {
      type: Number,
      required: false,
      default: Date.now(),
    },
    numApprovedReplies: {
      type: Number,
      required: false,
      default: 0,
    },
    numLikes: {
      type: Number,
      required: false,
      default: 0,
    },
    reactions: {
      type: Array,
      required: false,
      default: () => [],
    },
    voteScore: {
      type: Number,
      required: false,
      default: 0,
    },
    authorName: {
      type: String,
      required: true,
    },
    authorAvatar: {
      type: String,
      required: false,
      default: DEFAULT_USER_AVATAR,
    },
    authorId: {
      type: Number,
      required: true,
    },
    allowsEditing: {
      type: Boolean,
      required: false,
      default: true,
    },
    moderationStatus: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    numReports: {
      type: Number,
      required: true,
    },
    moderationScore: {
      type: Number,
      required: true,
    },
    dateUpdated: {
      type: Number,
      required: true,
    },
    replies: {
      type: Array,
      required: true,
    },
    userLiked: {
      type: Boolean,
      required: true,
    },
    userUpVoted: {
      type: Boolean,
      required: true,
    },
    userDownVoted: {
      type: Boolean,
      required: true,
    },
    canReact: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      isEditing: false,
      isReplying: false,
      commentEdit: "",
      newCommentReply: "",
      currentOffense: null,
      showMenuOptions: false,
      showReviewOptions: false,
      showReportOptions: false,
      likedComment: false,
      isSharing: false,
      bookmarkedComment: false,
    };
  },
  computed: {
    ...mapState(useConversationStore, [
      "isModerator",
      "moderationEnabled",
      "isMessageLoading",
    ]),
    ...mapState(useGeneralStore, ["userData", "userAvatar"]),
    isEditSaving() {
      if (!this.isMessageLoading[this.messageId]) {
        return false;
      }
      if (this.isMessageLoading[this.messageId].edit) {
        return true;
      }
      return false;
    },
    isAuthor() {
      return this.userData.user_id === this.authorId;
    },
    getAuthorName() {
      if (this.isAuthor) {
        return this.userData.first_name;
      }
      return this.authorName;
    },
    showAuthorPendingMessage() {
      return this.isAuthor && this.moderationStatus === "unknown";
    },
    showAuthorBlockedMessage() {
      return this.isAuthor && this.moderationStatus == "blocked";
    },
  },
  methods: {
    ...mapActions(useConversationStore, {
      fetchMore: "fetchMore",
      editMessage: "editMessage",
      likeMessage: "likeMessage",
      unLikeMessage: "unLikeMessage",
      deleteMessage: "deleteMessage",
      undeleteMessage: "undeleteMessage",
      reportMessage: "reportMessage",
      upVoteMessage: "upVoteMessage",
      revokeUpVoteMessage: "revokeUpVoteMessage",
      downVoteMessage: "downVoteMessage",
      revokeDownVoteMessage: "revokeDownVoteMessage",
      fetchComments: "fetchComments",
      replyToMessage: "replyToMessage",
      approveMessage: "approveMessage",
      blockMessage: "blockMessage",
      escalateMessage: "escalateMessage",
      fetchRepliesToMessage: "fetchRepliesToMessage",
      handleReaction: "handleReaction",
    }),
    turnOnIsEditing() {
      this.commentEdit = this.message;
      this.isEditing = true;
    },
    reactionSelected(reaction) {
      this.handleReaction(reaction, this.messageId);
    },
    turnOnIsReplying() {
      this.isReplying = !this.isReplying;
    },
    saveEdit() {
      if (!this.commentEdit) {
        return;
      }
      this.isEditing = false;
      this.editMessage(this.messageId, this.commentEdit);
    },
    cancelEditing() {
      this.isEditing = false;
    },
    deleteComment() {
      this.showMenuOptions = false;
      this.deleteMessage(this.messageId);
    },
    unDeleteComment() {
      this.showMenuOptions = false;
      this.undeleteMessage(this.messageId);
    },
    report(reason) {
      this.showReportOptions = false;
      this.reportMessage(this.messageId, reason);
    },
    reply(newMessage) {
      if (!newMessage) {
        return;
      }
      this.replyToMessage(this.messageId, newMessage);
    },
    like() {
      this.likeMessage(this.messageId);
    },
    unlike() {
      this.unLikeMessage(this.messageId);
    },
    upVote() {
      this.upVoteMessage(this.messageId);
    },
    revokeUpVote() {
      this.revokeUpVoteMessage(this.messageId);
    },
    downVote() {
      this.downVoteMessage(this.messageId);
    },
    revokeDownVote() {
      this.revokeDownVoteMessage(this.messageId);
    },
    block(reason) {
      this.blockMessage(this.messageId, reason);
    },
    approve() {
      this.approveMessage(this.messageId);
    },
    escalate() {
      this.escalateMessage(this.messageId);
    },
  },
  styleGuide: () => ({
    messageContainerBackground: { "color.background": "light" },
    borderRadius: { "size.borderRadius": "large" },
    actionBackground: { "color.background": "light" },
    actionBorderColor: { "color.border": "dark" },
    actionPadding: { "size.spacing": "small" },
    replyPadding: { "size.spacing": "standard" },
    containerPadding: { "size.spacing": "extraSmall" },
    modalBorderWidth: { "size.border": "thin" },
    modalBorderColor: { "color.border": "standard" },
  }),
};
</script>

<!-- eslint-disable-next-line -->
<style>
.message-container:has(.i-dropdown .i-dropdown-content) input {
  z-index: -1;
}
</style>

<style lang="scss" scoped>
.message-container {
  position: relative;
  padding-block: v-bind("$getStyles.containerPadding");

  &-preview {
    .divider {
      background: v-bind("$getStyles.modalBorderColor");
    }
  }
}

//.reply-editor {
//  border-radius: v-bind("$getStyles.borderRadius");
//  border: v-bind("$getStyles.modalBorderWidth") solid v-bind("$getStyles.modalBorderColor");
//  padding: v-bind("$getStyles.replyPadding");
//}

.message-container:hover {
  z-index: 1;
}

:deep(.i-text p) {
  margin: 0;
}

.message-container:hover {
  background: v-bind("$getStyles.messageContainerBackground");
}

:deep(.message-actions) {
  opacity: 0;
  position: absolute;
  right: 0;
  top: -24px;
  border-radius: v-bind("$getStyles.borderRadius");
  border: 1px solid v-bind("$getStyles.actionBorderColor");
  background: v-bind("$getStyles.actionBackground");
  transition: opacity 0.2s;
  padding: v-bind("$getStyles.actionPadding");
}

:deep(.message-actions:hover) {
  opacity: 1;
}

:deep(.message-actions:has(:deep(.message-dropdown:hover))) {
  opacity: 1;
}

.message-container:hover :deep(.message-actions) {
  opacity: 1;
}

.message-content {
  word-break: break-word;
}
</style>
