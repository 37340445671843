<template>
  <iColumn class="subscriptions">
    <iRow vertical-align="middle">
      <iSubHeading>
        Subscription Plans
      </iSubHeading>
      <iColumn width="hug">
        <iButton
          v-if="!isCreateSubscriptionModalOpen"
          variant="secondary"
          @click="openCreateSubscriptionModal"
        >
          Add Plan
        </iButton>
      </iColumn>
    </iRow>
    <iColumn class="subscription-plans">
      <iRow gap="extraLarge">
        <iColumn
          v-for="(plan, index) in activeSubscriptionPlans"
          :key="index"
          width="hug"
        >
          <iStyledCard
            :width="300"
            :title="plan.name"
            :subtitle="planSubtitle(plan)"
          >
            <template #title>
              <iRow align="between" vertical-align="middle" wrap="nowrap">
                <iSubHeading>{{ plan.name }}</iSubHeading>
                <iToggleSwitch
                  :model-value="isPlanAvailable(plan.subscription_plan_id)"
                  size="small"
                  @change="updateSubscriptionsConfig(plan.subscription_plan_id, $event)"
                />
              </iRow>
            </template>
          </iStyledCard>
        </iColumn>
      </iRow>
    </iColumn>
    <CreateSubscriptionModal :visible="isCreateSubscriptionModalOpen" @close="closeCreateSubscriptionModal" />
  </iColumn>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";
import { useConfigurationStore } from "@/stores/configuration-store";
import { MAPPED_CURRENCY_CODES } from "@/constants/currency-constants";
import CreateSubscriptionModal from "@/components/publication/CreateSubscriptionModal.vue";
export default {
  name: "MyPublicationSubscriptions",
  components: {
    CreateSubscriptionModal,
  },
  props: {
  },
  data() {
    return {
      isCreateSubscriptionModalOpen: false,
    };
  },
  computed: {
    ...mapState(useConfigurationStore, ["availableSubscriptionPlans"]),
    ...mapState(usePublicationStore, ["activeSubscriptionPlans", "validBillingFrequencies"]),
    subscriptionTypes() {
      return this.validBillingFrequencies.map(key => ({ name: key, id: key }));
    },
    currencyCodes() {
      return MAPPED_CURRENCY_CODES;
    },
    plans() {
      return this.subscriptionPlans;
    },
  },
  methods: {
    ...mapActions(useConfigurationStore, ["updateConfiguration", "updateSubscriptionPlan"]),
    planSubtitle(plan) {
      let string = "";
      if (plan.currency_code) {
        let currencyCode = this.getCurrencyCode(plan.currency_code);
        string += `${currencyCode.symbol}`;
      }
      if (plan.price) {
        string += `${plan.price} `;
      }
      if (plan.billing_frequency) {
        let billingFrequency = this.getBillingFrequency(plan.billing_frequency);
        string += ` ${billingFrequency.name}`;
      }
      return string;
    },
    isPlanAvailable(planId) {
      return this.availableSubscriptionPlans.some(availablePlanId => availablePlanId === planId);
    },
    getCurrencyCode(currencyCode) {
      return this.currencyCodes.find(code => code.key == currencyCode);
    },
    getBillingFrequency(billingFrequency) {
      return this.subscriptionTypes.find(frequency => frequency.id == billingFrequency);
    },
    updateSubscriptionPlanDetails(subscriptionPlanId) {
      let plan = this.plans.find(plan => plan.subscription_plan_id == subscriptionPlanId);
      this.updateSubscriptionPlan(subscriptionPlanId, plan.name, plan.description);
    },
    openCreateSubscriptionModal() {
      this.isCreateSubscriptionModalOpen = true;
    },
    closeCreateSubscriptionModal() {
      this.isCreateSubscriptionModalOpen = false;
    },
    updateSubscriptionsConfig(planId, isAvailable) {
      let availablePlans = [...this.availableSubscriptionPlans];
      if (!isAvailable) {
        availablePlans = availablePlans.filter(plan => plan != planId);
      } else {
        availablePlans.push(planId);
      }
      this.updateConfiguration({ availableSubscriptionPlans: availablePlans });
    },
  },
};
</script>

<style scoped>
</style>
