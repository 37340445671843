<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_PUBLICATION_FEATURES"
    title="Publication Features"
    :breadcrumbs="breadcrumbs"
    page-name="Features"
    align="left"
    width="full"
  >
    <PublicationSignupAndLogin />
    <PublicationEngagement />
    <PublicationNavigationConfig />
    <PublicationDomainConfiguration />
    <PublicationPostCreation />
    <PublicationUserPosting />
    <PublicationModeration />
    <PublicationShop />
    <PublicationSocialMedia />
  </PageWrapper>
</template>

<script>
import { mapState } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";
import PageWrapper from "@/components/PageWrapper";
import {
  PAGE_PUBLICATION_FEATURES,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";
import PublicationSignupAndLogin from "@/components/publication/features/PublicationSignupAndLogin.vue";
import PublicationEngagement from "@/components/publication/features/PublicationEngagement.vue";
import PublicationNavigationConfig from "@/components/publication/features/PublicationNavigationConfig.vue";
import PublicationDomainConfiguration from "@/components/publication/features/PublicationDomainConfiguration.vue";
import PublicationPostCreation from "@/components/publication/features/PublicationPostCreation.vue";
import PublicationUserPosting from "@/components/publication/features/PublicationUserPosting.vue";
import PublicationShop from "@/components/publication/features/PublicationShop.vue";
import PublicationSocialMedia from "@/components/publication/features/PublicationSocialMedia.vue";
import PublicationModeration from "@/components/publication/features/PublicationModeration.vue";
export default {
  name: "PublicationFeatures",
  components: {
    PageWrapper,
    PublicationSignupAndLogin,
    PublicationEngagement,
    PublicationNavigationConfig,
    PublicationDomainConfiguration,
    PublicationPostCreation,
    PublicationUserPosting,
    PublicationModeration,
    PublicationShop,
    PublicationSocialMedia,
  },
  data() {
    return {
      PAGE_PUBLICATION_FEATURES,
      SECTION_PUBLICATIONS,
    };
  },
  computed: {
    ...mapState(usePublicationStore, ["orgId", "publicationId"]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        { url: `/publication/${this.publicationId}`, label: "Overview" },
        { url: `/publication/${this.publicationId}/settings`, label: "Settings" },
      ];
    },
  },
};
</script>

<style scoped>
</style>
