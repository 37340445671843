<template>
  <PageWrapper :active-page="PAGE_JAVASCRIPT_TAG" :active-section="SECTION_MONETIZATION">
    <iColumn :width="900">
      <iHeading>
        Install Affiliate Link JavaScript
      </iHeading>
      <iRow>
        <iParagraph>
          Leverage your existing website by transforming links to your favorite products into affiliate links.
          By simply adding a script to the <strong>head</strong> tag of your site,
          you can start earning affiliate revenue from the posts your audience already loves.
        </iParagraph>
      </iRow>
      <iRow>
        <iParagraph>
          This integration allows you to monetize your content without altering your existing posts,
          maximizing your earning potential while providing valuable recommendations to your audience.
        </iParagraph>
      </iRow>
      <iRow>
        <iParagraph>
          Created affiliate links will be tracked in your inspiration.co reporting,
          giving you insights into which products resonate most with your audience.
        </iParagraph>
      </iRow>

      <template v-if="websites.length === 0 && !loadingWebsites">
        <iButton variant="primary" @click="openPublisherWebsiteModal">
          Add a website
        </iButton>
      </template>

      <template v-else>
        <iRow vertical-align="middle" wrap="nowrap">
          <iSubHeading>Websites</iSubHeading>
          <iButton variant="secondary" @click="openPublisherWebsiteModal">
            Add Website
          </iButton>
        </iRow>
        <iDataTable
          :items="websites"
          :fields="websiteTableFields"
          primary-key="publisher_website_id"
          show-empty-state
          empty-string="No website domains found."
          :has-more="hasMoreWebsites"
          :is-loading="loadingWebsites"
        >
          <template #td(script)="{data}">
            <iLink variant="tertiary" @click="openDisplayScriptModal(data.publisher_website_id)">
              Click here to view script
            </iLink>
          </template>
          <template #td(website_domain)="{data}">
            <iLink :href="`https://${data.website_domain}`" target="_blank">
              {{ data.website_domain }}
            </iLink>
          </template>
          <template #td(status)="{data}">
            <iStatus :status="getWebsiteStatus(data)" />
          </template>
          <template #td(actions)="{data}">
            <iRow align="right">
              <iDropdownMenu
                :show-dropdown="isDropdownVisible(data.publisher_website_id, 'actions')"
                position="right"
                :items="publisherWebsiteStatusesChangeTableOptions(data.status)"
                @click="(value) => clickDropdownItem(data, value)"
                @close="closeDropdown(data.publisher_website_id, 'actions')"
              >
                <template #trigger>
                  <iIcon icon="ellipsis" @click="toggleDropdown(data.publisher_website_id, 'actions')" />
                </template>
              </iDropdownMenu>
            </iRow>
          </template>
        </iDataTable>
      </template>
    </iColumn>

    <AddPublisherWebsiteModal
      :visible="isAddPublisherWebsiteModalVisible"
      :current-website="selectedWebsite"
      @close="closePublisherWebsiteModal"
      @submit="addPublisherWebsite"
      @update="updatePublisherWebsite"
    />
    <iModal
      :visible="isScriptModalVisible"
      :current-website="selectedWebsite"
      title="Copy Script"
      @close="closeDisplayScriptModal"
    >
      <template #body>
        <iRow>
          <iParagraph>
            Add this script to the <strong>head</strong> tag of your website to begin converting product links.
            Domain validation will occur automatically when a page is loaded.
          </iParagraph>
        </iRow>
        <iRow width="fill">
          <iCopyText :width="700" :text="scriptUrl" copy-message="Script Copied!" />
        </iRow>
      </template>
      <template #footer>
        <iRow width="fill" align="right">
          <iColumn width="hug">
            <iButton variant="secondary" width="hug" @click="closeDisplayScriptModal">
              Close
            </iButton>
          </iColumn>
        </iRow>
      </template>
    </iModal>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import { usePublisherStore } from "@/stores/publisher-store";
import { mapActions, mapState } from "pinia";
import { PAGE_JAVASCRIPT_TAG, SECTION_MONETIZATION } from "@/constants/nav-constants";
import { useGeneralStore } from "%/stores/general-store";
import AddPublisherWebsiteModal from "@/components/publisher-websites/AddPublisherWebsiteModal";
import { PUBLISHER_WEBSITE_DROPDOWN_LABELS, PUBLISHER_WEBSITE_DROPDOWN_KEYS } from "@/constants/publisher-website-constants";

export default {
  name: "JavascriptTag",
  components: {
    PageWrapper,
    AddPublisherWebsiteModal,
  },
  data() {
    return {
      PAGE_JAVASCRIPT_TAG,
      SECTION_MONETIZATION,
      publisherDomain: "",
      websiteTableFields: [
        { key: "website_domain", label: "Domain" },
        { key: "script", label: "Script" },
        { key: "status", label: "Status", width: "100px" },
        { key: "actions", label: "", width: "80px" },
      ],
      selectedWebsiteId: -1,
      selectedWebsite: null,
      dropdownStates: {},
      isAddPublisherWebsiteModalVisible: false,
      isScriptModalVisible: false,
      scriptModalWebsiteId: null,
    };
  },
  computed: {
    ...mapState(usePublisherStore, [
      "getInstallScript",
      "websites",
      "loadingWebsites",
      "hasMoreWebsites",
    ]),
    ...mapState(useGeneralStore, [
      "userId",
      "orgId",
    ]),
    scriptUrl() {
      const website = this.websites.find(website => website.publisher_website_id == this.selectedWebsiteId);

      if (!website) {
        return "Invalid URL";
      }

      const token = website.claim_token;
      const src = `https://cdn-alpha.inspiration.co/affiliate-js/0.0.1/publisher.js?id=${this.selectedWebsiteId}&token=${token}&orgId=${this.orgId}`;

      // eslint-disable-next-line no-useless-escape
      return `<script type="module" src="${src}"><\/script>`;
    },
  },
  mounted() {
    this.selectedWebsiteId = this.websites.length > 0 ? this.websites[0].publisher_website_id : -1;
  },
  methods: {
    ...mapActions(usePublisherStore, [
      "addPublisherWebsite",
      "updatePublisherWebsite",
    ]),
    publisherWebsiteStatusesChangeTableOptions(websiteStatus) {
      return Object.entries(PUBLISHER_WEBSITE_DROPDOWN_LABELS).map(status => {
        return {
          name: status[1],
          label: status[1],
          actionType: "action_type_button_click",
          actionValue: status[0],
        };
      }).filter(status => status.actionValue !== websiteStatus);
    },
    getWebsiteStatus(website) {
      if (website.status === "inactive") {
        return website.status;
      }

      return website.claim_status;
    },
    changeSelectedWebsiteId(id) {
      this.selectedWebsiteId = id;
    },
    toggleDropdown(id, type) {
      const openDropdownState = { [`${id}-${type}`]: !this.dropdownStates[`${id}-${type}`] };
      this.dropdownStates = { ...this.dropdownStates, ...openDropdownState };
    },
    closeDropdown(id, type) {
      const closeDropdownState = { [`${id}-${type}`]: false };
      this.dropdownStates = { ...this.dropdownStates, ...closeDropdownState };
    },
    isDropdownVisible(id, type) {
      return !!this.dropdownStates[`${id}-${type}`];
    },
    registerPublisher() {
      this.addPublisherDomain(this.publisherDomain, this.userId, this.ignoreQueryParams);
    },
    copyCode() {
      navigator.clipboard.writeText(this.scriptUrl);
    },
    clickDropdownItem(website, value) {
      const websiteId = website.publisher_website_id;

      switch (value) {
        case PUBLISHER_WEBSITE_DROPDOWN_KEYS.edit:
          this.selectedWebsite = website;
          this.isAddPublisherWebsiteModalVisible = true;
          break;
        case PUBLISHER_WEBSITE_DROPDOWN_KEYS.active:
        case PUBLISHER_WEBSITE_DROPDOWN_KEYS.inactive:
          this.updatePublisherWebsite(websiteId, website.website_domain, website.ignore_query_params, website.claim_status, value);
          break;
      }
    },
    openPublisherWebsiteModal() {
      this.isAddPublisherWebsiteModalVisible = true;
    },
    closePublisherWebsiteModal() {
      this.selectedWebsite = null;
      this.isAddPublisherWebsiteModalVisible = false;
    },
    openDisplayScriptModal(websiteId) {
      this.isScriptModalVisible = true;
      this.selectedWebsiteId = websiteId;
    },
    closeDisplayScriptModal() {
      this.isScriptModalVisible = false;
      this.selectedWebsiteId = null;
    },
  },
};
</script>

<style scoped>
.container {
  margin: 20px auto 0 auto;
  padding: 0 10px;
  max-width: 1280px;
  background: #fff;
  border: 1px solid lightgrey;
  border-radius: 8px;
  box-shadow: 0 2px 3px 0 rgba(193, 192, 192, 0.5);
}

.tracking-link-input {
  width: 100%;
  box-sizing: border-box;
  margin-top: 20px;
  height: 35px;
}

</style>
