<template>
  <iModal
    width="hug"
    :visible="visible"
    title="New Digital Product"
    primary-action-button-label="Create Digital Product"
    :primary-action-button-loading="isCreatingDigitalProduct"
    :primary-action-button-disabled="formHasError"
    @close="closeModal"
    @click:primary="addDigitalProduct"
    @click:secondary="closeModal"
  >
    <template #body>
      <iColumn width="fill">
        <iTextInput
          v-model="name"
          :required="true"
          label="Name"
          name="name"
          placeholder="Digital product name"
          type="text"
          width="fill"
        />
        <iTextInput
          v-model="description"
          :required="true"
          label="Description"
          name="description"
          placeholder="Best digital product..."
          type="text"
          width="fill"
          @blur="validateFormString"
        />
        <iColumn gap="extraSmall">
          <iLabel>
            Upload Digital Product
          </iLabel>
          <iUploader @upload-file="uploadDigitalProduct">
            <iRow vertical-align="middle">
              <iColumn width="hug">
                <iButton variant="secondary">
                  Add File
                </iButton>
              </iColumn>
              <iText v-if="file" variant="subtle">
                {{ file.name }}
              </iText>
            </iRow>
          </iUploader>
        </iColumn>
        <iTextInput
          v-model="price"
          :padding-left-multiplier="2"
          :required="true"
          label="Price"
          name="price"
          placeholder="23.99"
          width="fill"
        >
          <template #prepend>
            {{ currencyCode.symbol }}
          </template>
        </iTextInput>
        <iText v-if="formHasError" variant="error">
          {{ formError }}
        </iText>
      </iColumn>
    </template>
  </iModal>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";
import { useDigitalProductStore } from "@/stores/digital-product-store";
import { MAPPED_CURRENCY_CODES } from "@/constants/currency-constants";
import { validateStringInput } from "@bloglovin/vue-component-library";

export default {
  name: "CreateDigitalProductModal",
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["close"],
  data() {
    return {
      formError: "",
      name: "",
      description: "",
      digitalProductUrl: "",
      price: 0,
      currencyCode: MAPPED_CURRENCY_CODES[0],
      startDateAvailable: 0,
      endDateAvailable: 0,
      file: null,
    };
  },
  computed: {
    ...mapState(useDigitalProductStore, [
      "isCreatingDigitalProduct",
    ]),
    formHasError() {
      return !!this.formError;
    },
  },
  watch: {
    price(price) {
      this.validatePrice(price);
    },
  },
  methods: {
    ...mapActions(usePublicationStore, ["createDigitalProduct"]),
    closeModal(event) {
      this.$emit("close", event);
    },
    uploadDigitalProduct(e) {
      this.file = e;
    },
    ...mapActions(useDigitalProductStore, {
      createDigitalProduct: "createDigitalProduct",
    }),
    validateFormString(e) {
      this.formError = validateStringInput(e.target.value);
    },
    validatePrice(price) {
      const regex = /^[0-9]*\.?[0-9]*$/;
      if (!regex.test(price)) {
        this.formError = "Price must be a number";
      } else {
        this.formError = validateStringInput(price);
      }
    },
    addDigitalProduct() {
      if (this.formHasError) {
        return;
      }
      this.createDigitalProduct(
        this.name,
        this.description,
        this.digitalProductUrl,
        this.price,
        this.currencyCode,
        this.startDateAvailable,
        this.endDateAvailable,
        this.file,
        // eslint-disable-next-line @stylistic/js/comma-dangle
        this.closeModal
      );
      // this.closeModal();
    },
  },
};
</script>
