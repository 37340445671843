<template>
  <iColumn>
    <iColumn class="publication-header-wrapper" vertical-align="middle">
      <iOverlay
        :show-overlay-trigger="!canUploadLogo ? 'none' : isLoadingSplash ? 'always' :
          'hover' "
        class="splash-image"
        height="auto"
      >
        <iImageContainer
          :height="350"
          :src="splashImage"
          aspect-ratio="4/17"
          class="splash-image-container"
          object-fit="cover"
          width="fill"
        />
        <template #overlay>
          <iColumn
            align="center"
            height="fill"
            vertical-align="middle"
            width="fill"
          >
            <iLoadingSpinner v-if="isLoadingSplash" size="large" />
            <iColumn v-else width="hug">
              <iButton @click="$emit('click:splash-image')">
                Upload Splash
              </iButton>
            </iColumn>
          </iColumn>
        </template>
      </iOverlay>

      <iRow class="publication-header" vertical-align="middle" wrap="nowrap">
        <iColumn width="hug">
          <iOverlay
            :show-overlay-trigger="!canUploadLogo ?'none' : isLoadingLogo ? 'always' : 'hover' "
            height="auto"
            width="auto"
          >
            <iImageContainer
              :height="$vssMobile ? 100 : 120"
              :src="logoImage"
              :width="$vssMobile ? 100 : 120"
              class="publication-header-logo"
              object-fit="cover"
              shape="circle"
            />
            <template #overlay>
              <iColumn
                align="center"
                height="fill"
                vertical-align="middle"
                width="fill"
              >
                <iLoadingSpinner v-if="isLoadingLogo" size="large" />
                <iColumn v-else width="hug">
                  <iButton @click="$emit('click:logo-image')">
                    Upload
                  </iButton>
                </iColumn>
              </iColumn>
            </template>
          </iOverlay>
        </iColumn>
        <iRow
          align="between"
          vertical-align="middle"
          gap="small"
          :wrap="wrapMenu ? 'wrap' : 'nowrap'"
        >
          <iColumn width="hug" gap="none">
            <iSpace :height="24" />
            <iRow
              v-if="canEditTitle"
              :width="500"
              max-width-only
              vertical-align="middle"
              wrap="nowrap"
            >
              <iTextEditInline
                :model-value="internalTitle"
                width="hug"
                font-size="extraLarge"
                placeholder="Title"
                :max-length="32"
                :show-icon-on-edit="false"
                @update:model-value="saveTitle"
              />
            </iRow>
            <iRow v-else-if="title || $slots['no-edit-title']" max-width-only :width="500">
              <slot name="no-edit-title">
                <iSubHeading>
                  {{ title }}
                </iSubHeading>
              </slot>
            </iRow>
            <slot name="details">
              <iRow
                v-if="canEditDetails"
                max-width-only
                vertical-align="middle"
                wrap="nowrap"
              >
                <iTextEditInline
                  width="hug"
                  :model-value="internalDetails"
                  placeholder="Title"
                  :max-length="255"
                  :show-icon-on-edit="false"
                  @update:model-value="saveDetails"
                />
              </iRow>
              <iRow v-else-if="details" max-width-only :width="500">
                <iText variant="subtle">
                  {{ details }}
                </iText>
              </iRow>
            </slot>
            <slot v-if="$slots['below-details']" name="below-details" />
          </iColumn>
          <slot name="menu" />
        </iRow>
      </iRow>
    </iColumn>
    <iColumn class="main-content">
      <slot />
    </iColumn>
  </iColumn>
</template>

<script>
import { mapState } from "pinia";
import { useWindowStore } from "@bloglovin/vue-component-library";

export default {
  name: "ProfileWrapper",
  props: {
    splashImage: {
      type: String,
      required: true,
    },
    isLoadingSplash: {
      type: Boolean,
      required: false,
      default: false,
    },
    logoImage: {
      type: String,
      required: true,
    },
    isLoadingLogo: {
      type: Boolean,
      required: false,
      default: false,
    },
    canUploadSplash: {
      type: Boolean,
      required: false,
      default: false,
    },
    canUploadLogo: {
      type: Boolean,
      required: false,
      default: false,
    },
    canEditTitle: {
      type: Boolean,
      required: false,
      default: false,
    },
    canEditDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    details: {
      type: String,
      required: false,
      default: "",
    },
    wrapMenu: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: [
    "click:splash-image",
    "click:logo-image",
    "click:save-title",
    "click:save-details",
  ],
  data() {
    return {
      internalTitle: this.title,
      internalDetails: this.details,
    };
  },
  computed: {
    ...mapState(useWindowStore, [
      "$vssMobile",
    ]),
  },
  watch: {
    title(newTitle) {
      this.internalTitle = newTitle;
    },
    details(newDetails) {
      this.internalDetails = newDetails;
    },
  },
  created() {
    useWindowStore().init();
  },
  beforeUnmount() {
    useWindowStore().remove();
  },
  styleGuide: () => ({
    logoBorderWidth: { "size.border": "thick" },
    logoBorderColor: { "color.border": "dark" },
    logoBackground: { "color.background": "light" },
  }),
  methods: {
    saveTitle(title) {
      this.$emit("click:save-title", title);
    },
    saveDetails(details) {
      this.$emit("click:save-details", details);
    },
  },
};
</script>

<style lang="scss" scoped>
.publication-header-wrapper {
  position: relative;
}

.publication-header {
  position: relative;
  bottom: 30px;

  @include tablet {
    bottom: 21px;
  }
}

.publication-header-logo {
  border: v-bind("$getStyles.logoBorderWidth") solid v-bind("$getStyles.logoBorderColor");
  background: v-bind("$getStyles.logoBackground")
}

.main-content {
  position: relative;
  top: -40px;
}

.splash-image-container {
  height: 100%;
  aspect-ratio: 12/4;
}

.profile-image-container {
  height: 100%;
  aspect-ratio: 4/4;
}
</style>
