<template>
  <PageWrapper
    :active-section="SECTION_BILLING"
    :active-page="PAGE_BILLING_HISTORY"
    title="Billing History"
    :breadcrumbs="breadcrumbs"
    page-name="Billing History"
    align="left"
    width="full"
  >
    <iColumn gap="extraLarge">
      <iRow gap="standard">
        <iSubHeading variant="subtle">
          Billing History
        </iSubHeading>
      </iRow>
    </iColumn>
  </PageWrapper>
</template>

<script>
import { useGeneralStore } from "%/stores/general-store";
import PageWrapper from "@/components/PageWrapper";
import {
  PAGE_BILLING_HISTORY,
  SECTION_BILLING,
} from "@/constants/nav-constants";
import { mapState } from "pinia";

export default {
  name: "BillingHistory",
  components: {
    PageWrapper,
  },
  data() {
    return {
      SECTION_BILLING,
      PAGE_BILLING_HISTORY,
    };
  },
  computed: {
    ...mapState(useGeneralStore, ["settingsBreadcrumbs"]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: "/billing", label: "Billing" },
      ];
    },
  },
};
</script>
