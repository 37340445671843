<template>
  <iColumn>
    <iRow>
      <iTextEdit
        value="My Feed"
        variant="locked"
        show-as-text
        @submit="createNewFeedGroup"
        @cancel="closeCreateFeedGroup"
      >
        <template #display>
          <iButton>
            Add Feed Group
          </iButton>
        </template>
      </iTextEdit>
    </iRow>
    <iInfiniteScroll
      :height="200"
      :has-more="hasMoreFeedGroups"
      :is-loading="isLoadingFetchFeedGroups"
      :scroll-threshold="0"
      @load-more="loadMoreFeedGroups"
    >
      <iRow
        v-for="feedGroup, index in selectableFeedGroups"
        :key="index"
        width="fill"
        align="between"
        vertical-align="middle"
        @click="handleSelectFeedGroup(feedGroup.feed_group_id)"
      >
        <iColumn width="hug">
          <iTitle> {{ feedGroup.name }}</iTitle>
        </iColumn>
        <iColumn width="hug" vertical-align="middle">
          <iCheckbox :model-value="isSelectedFeedGroup(feedGroup.feed_group_id)" />
        </iColumn>
      </iRow>
    </iInfiniteScroll>
  </iColumn>
</template>
<script>
import { mapActions, mapState } from "pinia";
import { useFeedStore } from "@/stores/feed-store";

export default {
  name: "FeedGroupSelector",
  props: {
    modelValue: {
      type: [Number, String],
      required: false,
      default: 0,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    ...mapState(useFeedStore, ["hasMoreFeedGroups", "feedGroups", "isLoadingFetchFeedGroups"]),
    selectableFeedGroups() {
      return this.feedGroups;
    },
    selectedFeedGroupId() {
      return parseInt(this.modelValue);
    },
  },
  methods: {
    ...mapActions(useFeedStore, ["loadMoreFeedGroups", "createFeedGroup"]),
    isSelectedFeedGroup(feedGroupId) {
      return feedGroupId === this.selectedFeedGroupId;
    },
    handleSelectFeedGroup(feedGroupId) {
      this.$emit("update:modelValue", feedGroupId);
    },
    createNewFeedGroup(name) {
      this.createFeedGroup(name, () => {
        this.loadMoreFeedGroups();
      });
    },
  },
};
</script>
