<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_PUBLICATION_DEPLOYMENT"
    title="Deployment"
    :breadcrumbs="breadcrumbs"
    page-name="Deployment"
    align="left"
    width="full"
  >
    <iRow wrap="nowrap">
      <iColumn :width="700" gap="large">
        <!-- Add a call to action slot to timeline component -->
        <iTimeLine :items="mappedPublicationVersions">
          <template #latest-timeline-actions>
            <iColumn width="hug">
              <iButton variant="secondary" @click="openPublicationDeploymentConfirmationModal">
                Deploy
              </iButton>
            </iColumn>
          </template>
        </iTimeLine>
      </iColumn>
    </iRow>
    <PublicationDeploymentConfirmationModal
      :visible="isPublicationDeploymentConfirmationModalOpen"
      @close="closePublicationDeploymentConfirmationModal"
    />
  </PageWrapper>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";

import PageWrapper from "@/components/PageWrapper";
import { getRelativeTimeString } from "@bloglovin/vue-component-library";
import {
  PAGE_PUBLICATION_DEPLOYMENT,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";
import dayjs from "dayjs";
import PublicationDeploymentConfirmationModal from "@/components/publication/PublicationDeploymentConfirmationModal.vue";

export default {
  name: "PublicationDeployment",
  components: {
    PageWrapper,
    PublicationDeploymentConfirmationModal,
  },
  props: {
    deployments: {
      type: Array,
      required: true,
    },
    publicationVersions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      PAGE_PUBLICATION_DEPLOYMENT,
      SECTION_PUBLICATIONS,
      isModalOpen: false,
      runAction: () => {},
      selectedVersion: 0,
      openDropdownIndex: -1,
      isPublicationDeploymentConfirmationModalOpen: false,
    };
  },
  computed: {
    ...mapState(usePublicationStore, ["orgId", "publicationId"]),
    mappedPublicationVersions() {
      const groupedVersions = [];
      let subGroupedVersions = [];
      this.publicationVersions.forEach((version, index) => {
        const by = version.username || "Unknown";
        const date = dayjs.unix(version.date_created).format("h:mma MMMM D, YYYY");
        const description = `by ${by} at ${date}`;
        const memo = version.memo || "Unknown changes";
        const isCurrentProduction = version.production_version_id === version.publication_version_id;
        let title = isCurrentProduction ? "Deployment" : `${memo}`;

        let workingVersion = version.version.split(".")[0];

        const item = {
          title,
          description,
          bubbleSmall: !isCurrentProduction,
          primary: isCurrentProduction,
          secondary: index === 0 && !isCurrentProduction,
          bubbleText: "",
          version: workingVersion,
          firstIndex: index === 0,
        };

        const lastItemInGroup = subGroupedVersions[0];
        if (!lastItemInGroup) {
          subGroupedVersions.push(item);
        } else {
          if (lastItemInGroup.version === workingVersion && index < this.publicationVersions.length - 1) {
            subGroupedVersions.push(item);
          } else {
            if (index === this.publicationVersions.length - 1) {
              subGroupedVersions.push(item);
            }
            if (!lastItemInGroup.firstIndex) {
              lastItemInGroup.title = "Deployment";
              lastItemInGroup.bubbleSmall = false;
            }

            groupedVersions.push({
              ...lastItemInGroup,
            });
            subGroupedVersions.splice(0, 1);
            if (subGroupedVersions.length > 3) {
              groupedVersions.push({
                title: "Multiple Changes",
                description: "",
                bubbleText: "",
                children: subGroupedVersions,
              });
            } else {
              groupedVersions.push(...subGroupedVersions);
            }

            subGroupedVersions = [item];
          }
        }
      });

      return groupedVersions;
    },
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        { url: `/publication/${this.publicationId}`, label: "Overview" },
      ];
    },
    isDropdownOpen() {
      return index => this.openDropdownIndex === index;
    },
  },
  methods: {
    ...mapActions(usePublicationStore, ["rollbackPublicationVersion"]),
    getRelativeTimeString,
    openPublicationDeploymentConfirmationModal() {
      this.isPublicationDeploymentConfirmationModalOpen = true;
    },
    closePublicationDeploymentConfirmationModal() {
      this.isPublicationDeploymentConfirmationModalOpen = false;
    },
  },
};
</script>
