<template>
  <iHeading> Engagement </iHeading>
  <iText v-if="!isSignupAndLoginEnabled" variant="subtle">
    Login and Signup Must be enabled to access these features
  </iText>

  <iParagraph>
    You can control how users interact with your content. Toggle the settings
    below to enable or disable the features you want
  </iParagraph>

  <iColumn>
    <iRow vertical-align="middle">
      <iSubHeading>Liking posts</iSubHeading>
      <iToggleSwitch
        :model-value="canLikePosts"
        label=""
        :disabled="!isSignupAndLoginEnabled"
        @change="updateEngagementConfiguration('canLikePosts', $event)"
      />
    </iRow>
    <iText> Allow users to like your posts </iText>
  </iColumn>

  <iColumn>
    <iRow vertical-align="middle">
      <iSubHeading>Voting on posts</iSubHeading>
      <iToggleSwitch
        :model-value="canVoteOnPosts"
        label=""
        :disabled="!isSignupAndLoginEnabled"
        @change="updateEngagementConfiguration('canVoteOnPosts', $event)"
      />
    </iRow>
    <iText> Allow users to vote on your posts </iText>
  </iColumn>

  <iColumn>
    <iRow vertical-align="middle">
      <iSubHeading>Commenting on posts</iSubHeading>
      <iToggleSwitch
        :model-value="canCommentOnPosts"
        label=""
        :disabled="!isSignupAndLoginEnabled"
        @change="updateEngagementConfiguration('canCommentOnPosts', $event)"
      />
    </iRow>
    <iText>
      Your publications' posts can have comments, and those can have different
      interactions. Choose which ones of the interactions you want your fans
      and collaborators to use on the posts.
    </iText>
    <iRow vertical-align="middle">
      <iCheckbox
        :model-value="canLikeComments"
        label="Allow users to like comments"
        :disabled="!isSignupAndLoginEnabled"
        @change="updateEngagementConfiguration('canLikeComments', $event)"
      />
    </iRow>
    <iRow vertical-align="middle">
      <iCheckbox
        :model-value="canVoteOnComments"
        label="Allow users to vote on comments"
        :disabled="!isSignupAndLoginEnabled"
        @change="updateEngagementConfiguration('canVoteOnComments', $event)"
      />
    </iRow>
    <iRow vertical-align="middle">
      <iCheckbox
        :model-value="canReplyToComments"
        label="Allow users to reply to comments"
        :disabled="!isSignupAndLoginEnabled"
        @change="updateEngagementConfiguration('canReplyToComments', $event)"
      />
    </iRow>
  </iColumn>
</template>

<script>
import { PUBLICATION_COMMENTS_DEFAULT_CONFIG } from "%/constants/publication-constants";
import { useConfigurationStore } from "@/stores/configuration-store";
import { usePublicationStore } from "@/stores/publication-store";
import { mapActions, mapState } from "pinia";

export default {
  name: "PublicationEngagement",
  data() {
    return {
      PUBLICATION_COMMENTS_DEFAULT_CONFIG,
    };
  },
  computed: {
    ...mapState(usePublicationStore, [
      "orgId",
      "publication",
      "posts",
      "isLoading",
    ]),
    ...mapState(useConfigurationStore, [
      "isSignupAndLoginEnabled",
      "canLikePosts",
      "canVoteOnPosts",
      "canCommentOnPosts",
      "canLikeComments",
      "canVoteOnComments",
      "canReplyToComments",
    ]),
  },
  methods: {
    ...mapActions(useConfigurationStore, ["updateConfiguration"]),
    async updateEngagementConfiguration(key, value) {
      await this.updateConfiguration({ [key]: value });
    },
  },
};
</script>

<style scoped lang="scss"></style>
