import { defineStore } from "pinia";
import { useGeneralStore } from "%/stores/general-store";
import { usePublicationStore } from "@/stores/publication-store";
import { links } from "%/enums/link-enums";
import { useFeedGroupFeedStore } from "SmartFeed/stores/feed/feed-group-feed-store";
import SmartFeedEngine from "SmartFeed/smart-feed-engine";

export const useFeedStore = defineStore("feed-store", {
  state: () => ({
    feedGroupId: 0,
    isLoadingUpdateFeedGroupName: false,
    isLoadingUpdateFeedGroupStatus: false,
    isLoadingFetchFeedGroups: false,
    feedGroups: [],
    hasMoreFeedGroups: true,
    lastFeedGroupId: 0,
  }),
  getters: {
    orgId() {
      return useGeneralStore().orgId;
    },
    affiliateJsUrl() {
      return useGeneralStore().getLink(links.affiliateJs);
    },
    affiliateApiV1Url() {
      return useGeneralStore().getLink(links.affiliateApiV1);
    },
    publicationId() {
      return usePublicationStore().publicationId;
    },
  },
  actions: {
    createFeedGroup(name, finalHandler) {
      this.isLoadingCreateFeedGroup = true;

      this.apiPost(`/feeds/publication/${this.publicationId}/feed-group`, {
        name,
      }, () => {
        useFeedGroupFeedStore().reload();
      }, () => {

      }, () => {
        this.isLoadingCreateFeedGroup = false;
        finalHandler();
      });
    },
    updateFeedGroupName(name, feedGroupId) {
      this.isLoadingUpdateFeedGroupName = true;

      this.apiPost(`/feeds/publication/${this.publicationId}/feed-group/${feedGroupId}/name`, {
        name,
      }, data => {
        new SmartFeedEngine().addEntities(data.entities);
      }, () => {
      }, () => {
        this.isLoadingUpdateFeedGroupName = false;
      });
    },
    updateFeedGroupStatus(status, feedGroupId) {
      this.isLoadingUpdateFeedGroupStatus = true;

      this.apiPost(`/feeds/publication/${this.publicationId}/feed-group/${feedGroupId}/status`, {
        status,
      }, () => {
        useFeedGroupFeedStore().reload();
      }, () => {
      }, () => {
        this.isLoadingUpdateFeedGroupStatus = false;
      });
    },
    loadMoreFeedGroups() {
      this.isLoadingFetchFeedGroups = true;

      this.apiGet(`/feeds/publication/${this.publicationId}/feed-groups`, {
        last_id: this.lastFeedGroupId,
      }, data => {
        this.hasMoreFeedGroups = data.hasMore;
        this.feedGroups = [...this.feedGroups, ...data.feedGroups];
        this.lastFeedGroupId = data.lastId;
      }, () => {
      }, () => {
        this.isLoadingFetchFeedGroups = false;
      });
    },
  },
});
