import { camelToKebabCase, camelToTitleCase, snakeToTitleCase } from "@bloglovin/vue-framework";

const BaseSmartFeedEngine = class {

  static USE_PINIA_STORE = "usePiniaStore";
  static LAYOUT_COMPONENTS = "layoutComponents";

  static ENTITY_LAYOUT_CARD_LARGE = "cardLarge";
  static ENTITY_LAYOUT_CARD_SMALL = "cardSmall";
  static ENTITY_LAYOUT_TABLE_ROW = "tableRow";

  static SMART_FEED_LAYOUT_CAROUSEL = "carousel";
  static SMART_FEED_LAYOUT_GRID = "grid";
  static SMART_FEED_LAYOUT_LIST = "list";
  static SMART_FEED_LAYOUT_TABLE = "table";

  static VALID_ENTITY_LAYOUTS = [
    this.ENTITY_LAYOUT_CARD_LARGE,
    this.ENTITY_LAYOUT_CARD_SMALL,
    this.ENTITY_LAYOUT_TABLE_ROW,
  ];

  static VALID_SMART_FEED_LAYOUTS = [
    this.SMART_FEED_LAYOUT_CAROUSEL,
    this.SMART_FEED_LAYOUT_GRID,
    this.SMART_FEED_LAYOUT_LIST,
    this.SMART_FEED_LAYOUT_TABLE,
  ];

  async getStoreFunctionForEntity(entityType) {
    const titleCaseEntityType = camelToTitleCase(entityType, false);
    const kebabCaseEntityType = camelToKebabCase(entityType);
    const storeName = `use${titleCaseEntityType}EntityStore`;

    const resolvedDynamicImport = await import(`SmartFeed/stores/entity/${kebabCaseEntityType}-entity-store`);
    return resolvedDynamicImport[storeName];
  }

  async getLayoutComponentForEntity(entityType, layoutType) {
    const titleCaseEntityType = snakeToTitleCase(entityType, false);
    const kebabCaseEntityType = camelToKebabCase(entityType);
    const titleCaseLayoutType = snakeToTitleCase(layoutType, false);

    // try {
    const resolvedDynamicImport = await import(`SmartFeed/components/${kebabCaseEntityType}/layouts/${titleCaseEntityType}${titleCaseLayoutType}`);
    return resolvedDynamicImport.default;
    // } catch (error) {
    //   // throw new SmartFeedError("Unable to find smart feed component for layout", {
    //   //   entityType: entityType,
    //   //   layoutType: layoutType,
    //   //   smartFeedComponentPath: smartFeedComponentPath,
    //   // })
    //   throw error;
    // }
  }

  async getEntity(entityType, entityId) {
    const usePiniaStore = await this.getStoreFunctionForEntity(entityType);
    const store = usePiniaStore();
    return store.getEntity(entityId);
  }

  async getEntityStore(entityType) {
    const usePiniaStore = await this.getStoreFunctionForEntity(entityType);
    const store = usePiniaStore();
    return store;
  }

  async addEntities(entities) {
    for (const [entityType, entityItems] of Object.entries(entities)) {
      const usePiniaStore = await this.getStoreFunctionForEntity(entityType);
      const store = usePiniaStore();
      store.addEntities(entityItems);
    }
  }
};

export default BaseSmartFeedEngine;
