<template>
  <iHeading> Moderation </iHeading>
  <iSelect
    :model-value="commentModerationStrategy"
    return-value
    :items="validCommentModerationStrategyTypes"
    label="Comment Moderation"
    :disabled="!canCommentOnPosts"
    value-field="value"
    @update:model-value="updateCommentModerationConfig"
  />
  <!-- TODO: disable if users cannot comment on posts (pending VCL update to ISelect)-->
  <iSelect
    :model-value="ugcModerationStrategy"
    return-value
    :items="validUgcModerationStrategyTypes"
    label="User Generated Content Moderation"
    :disabled="!canUserCreatePost"
    value-field="value"
    @update:model-value="updateCommentModerationConfig"
  />
  <!-- disable if users cannot create posts (pending VCL update to ISelect)-->
</template>

<script>
import { useConfigurationStore } from "@/stores/configuration-store";
import { mapActions, mapState } from "pinia";
export default {
  name: "PublicationModeration",
  computed: {
    ...mapState(useConfigurationStore, [
      "canUserCreatePost",
      "canCommentOnPosts",
      "ugcModerationStrategy",
      "commentModerationStrategy",
    ]),
    validCommentModerationStrategyTypes() {
      return [{ name: "None", value: "none" }];
    },
    validUgcModerationStrategyTypes() {
      return [{ name: "None", value: "none" }];
    },
  },
  methods: {
    ...mapActions(useConfigurationStore, ["updateConfiguration"]),
    async updateUgcModerationConfig(moderationStrategy) {
      if (moderationStrategy !== this.ugcModerationStrategy) {
        this.updateConfiguration({ ugcModerationStrategy: moderationStrategy });
      }
    },
    async updateCommentModerationConfig(moderationStrategy) {
      if (moderationStrategy !== this.commentModerationStrategy) {
        this.updateConfiguration({
          commentModerationStrategy: moderationStrategy,
        });
      }
    },
  },
};
</script>

<style scoped></style>
