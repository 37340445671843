import { defineStore } from "pinia";
import { useLocalStorage } from "@vueuse/core";

export const useFeatureStore = defineStore("feature-store", {
  state: () => ({
    features: useLocalStorage("use-feature-store--features", {}),
  }),
  getters: {
    isFeatureEnabled: state => featureName => {
      return state.features[featureName].enabled;
    },
    isFeatureToggled: state => featureName => {
      return state.features[featureName].enabled !== state.features[featureName].enabled_by_default;
    },
  },
  actions: {
    updateFeatureConfig(featureConfig) {
      const features = {};

      for (const featureName in featureConfig) {
        const remoteValue = featureConfig[featureName];
        const localValue = this.features[featureName];

        if (!localValue) {
          features[featureName] = remoteValue;
          continue;
        }

        if (localValue.toggled) {
          if (localValue.enabled === remoteValue.enabled) {
            features[featureName] = remoteValue;
          } else {
            features[featureName] = localValue;
          }
        } else {
          features[featureName] = remoteValue;
        }
      }

      this.features = features;
    },
  },
});
