import { defineStore } from "pinia";
import { usePublicationStore } from "~/stores/publication-store";
export const useConfigurationStore = defineStore("configuration-store", {
  state: () => ({
    $_configuration: {}, // used as a private state variable
  }),
  getters: {
    publicationId() {
      return usePublicationStore().publicationId;
    },
    publicationStagingVersionId() {
      return usePublicationStore().publicationVersionId;
    },
    areSubscriptionsAvailable() {
      return this.canSignUp && this.availableSubscriptionPlans.length > 0;
    },
    canCreateQuickPost() {
      return this.ugcInterface == "quickPost";
    },
    isSignupAndLoginEnabled() {
      return this.canSignUp || this.isPrivatePublication;
    },
    primaryNavigation() {
      return this.$_configuration.primaryNav;
    },
    availableSubscriptionPlans() {
      return this.$_configuration.availableSubscriptionPlans;
    },
    canVoteOnPosts() {
      return this.$_configuration.canVoteOnPosts;
    },
    canLikePosts() {
      return this.$_configuration.canLikePosts;
    },
    commentModerationStrategy() {
      return this.$_configuration.commentModerationStrategy;
    },
    commentReplyDisplayStyle() {
      return this.$_configuration.commentReplyDisplayStyle;
    },
    digitalProductsVisible() {
      return this.$_configuration.digitalProductsVisible;
    },
    commentReplyNestingLimit() {
      return this.$_configuration.commentReplyNestingLimit;
    },
    canLikeComments() {
      return this.$_configuration.canLikeComments;
    },
    isPrivatePublication() {
      return this.$_configuration.isPrivatePublication;
    },
    requireAccountApproval() {
      return this.$_configuration.requireAccountApproval;
    },
    ugcInterface() {
      return this.$_configuration.ugcInterface;
    },
    freeCommunityType() {
      return this.$_configuration.freeCommunityType;
    },
    exclusiveCommunityType() {
      return this.$_configuration.exclusiveCommunityType;
    },
    commentDefaultSortingStrategy() {
      return this.$_configuration.commentDefaultSortingStrategy;
    },
    ugcModerationStrategy() {
      return this.$_configuration.ugcModerationStrategy;
    },
    canCommentOnPosts() {
      return this.$_configuration.canCommentOnPosts;
    },
    canReplyToComments() {
      return this.$_configuration.canReplyToComments;
    },
    canVoteOnComments() {
      return this.$_configuration.canVoteOnComments;
    },
    canSignUp() {
      return this.$_configuration.canSignUp;
    },
  },
});
