<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_PUBLICATION_FEED_GROUP_LIST"
    title="Feed Blogs"
    :breadcrumbs="breadcrumbs"
    page-name="Feed Blogs"
    align="left"
    width="full"
  >
    <iRow width="fill" align="right">
      <iButton @click="openAddBlogModal">
        Add Blog
      </iButton>
    </iRow>
    <!-- <SmartFeed :pinia-store="useFeedGroupBlogFeedStore" default-layout="small-card" /> -->
    <iModal
      :visible="isAddBlogModalOpen"
      title="Add Blog to Feed"
      :is-loading="isLoadingAddBlogToFeedGroup"
    >
      <template #body>
        <iRow>
          <!-- <search bar> -->
        </iRow>
      </template>
    </iModal>
  </PageWrapper>
</template>

<script>
import { mapActions, mapState } from "pinia";
import PageWrapper from "@/components/PageWrapper";
import {
  PAGE_PUBLICATION_FEED_GROUP_LIST,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";
import { usePublicationStore } from "@/stores/publication-store";
import { useFeedStore } from "@/stores/feed-store";
import { useFeedGroupBlogFeedStore } from "SmartFeed/stores/feed/feed-group-blog-feed-store";
// import SmartFeed from "@/components/stores/SmartFeed";
export default {
  name: "PublicationFeedGroup",
  components: {
    PageWrapper,
    // SmartFeed,
  },
  data() {
    return {
      PAGE_PUBLICATION_FEED_GROUP_LIST,
      SECTION_PUBLICATIONS,
      isAddBlogModalOpen: false,
      searchTerm: "",
    };
  },
  computed: {
    ...mapState(usePublicationStore, ["publicationId", "orgId"]),
    ...mapState(useFeedStore, ["isLoadingAddBlogToFeedGroup"]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        { url: `/publication/${this.publicationId}`, label: "Overview" },
        { url: `/publication/${this.publicationId}/feed-groups`, label: "RSS Content" },
      ];
    },
  },
  methods: {
    useFeedGroupBlogFeedStore,
    ...mapActions(useFeedStore, ["addBlogToFeedGroup"]),
    openAddBlogModal() {
      this.isAddBlogModalOpen = true;
    },
    closeAddBlogModal() {
      this.isAddBlogModalOpen = false;
    },
    handleAddBlog(blog) {
      this.addBlogToFeedGroup(blog);
    },
  },
};
</script>

<style scoped>
</style>
