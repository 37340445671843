<template>
  <PageWrapper
    :active-section="SECTION_COMMUNITY"
    :active-page="PAGE_COMMUNITY_PUBLICATIONS"
    title="Publications"
    sub-title=""
    :breadcrumbs="breadcrumbs"
    page-name="Publications"
    align="center"
    width="full"
  >
    <iColumn v-if="publications?.length === 0">
      <iSpace :height="1" />
      <iText variant="subtle">
        No Publications Found.
      </iText>
    </iColumn>
    <SmartFeed :pinia-store="useCommunityPublicationFeedStore" default-entity-layout="cardSmall" default-smart-feed-layout="grid" />
  </PageWrapper>
</template>

<script>
import { mapActions, mapState } from "pinia";
import PageWrapper from "@/components/PageWrapper";
import { SECTION_COMMUNITY, PAGE_COMMUNITY_PUBLICATIONS } from "@/constants/nav-constants";
import { usePublicationStore } from "@/stores/publication-store";
import SmartFeed from "SmartFeed/components/SmartFeed";
import { useCommunityPublicationFeedStore } from "SmartFeed/stores/feed/community-publication-feed-store";
export default {
  name: "CommunityPublications",
  components: {
    PageWrapper,
    SmartFeed,
  },
  data() {
    return {
      SECTION_COMMUNITY,
      PAGE_COMMUNITY_PUBLICATIONS,
      hasMorePublications: true,
    };
  },
  computed: {
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: "/community", label: "Community" },
      ];
    },
    ...mapState(usePublicationStore, [
      "communityPublications",
      "communityPublicationsLoading",
      "lastCommunityPublicationId",
      "hasMoreCommunityPublications",
    ]),
  },

  methods: {
    useCommunityPublicationFeedStore,
    ...mapActions(usePublicationStore, [
      "getCommunityPublications",
    ]),
    loadMorePublications() {
      this.getCommunityPublications();
    },
  },
};
</script>
