<template>
  <iLoadingSpinner v-if="isLoading" />
  <iColumn
    v-else
    class="form-body"
    max-width-only
    :width="400"
  >
    <Logo />
    <iHeading :subtle="true" text="Reset Password" />

    <iTextInput
      v-model="password"
      name="password: "
      placeholder="***********"
      label="new password"
      :required="true"
      width="fill"
      type="password"
    />
    <iTextInput
      v-model="confirmPassword"
      name="confirmPassword: "
      placeholder="***********"
      label="confirm password"
      :required="true"
      width="fill"
      :disabled="!validPassword"
      type="password"
    />
    <PasswordComplexity
      :password="password"
      :confirm-password="confirmPassword"
      @update:password="validatePasswordComplexity"
    />

    <iText v-if="hasError" :style-overrides="{fontColor: 'error'}">
      {{ resetPasswordErrorMessage }}
    </iText>

    <iButton
      type="submit"
      :disabled="!getPasswordComplexityCorrect"
      @click="submitResetPasswordForm"
    >
      Save Changes
    </iButton>
  </iColumn>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useUserStore } from "@/stores/user-store";
import Logo from "@/components/Logo";
import PasswordComplexity from "@/components/PasswordComplexity";

export default {
  name: "ResetPassword",
  components: {
    Logo,
    PasswordComplexity,
  },
  props: {
    token: {
      type: String,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      password: "",
      confirmPassword: "",
      passwordError: null,
      validPassword: null,
      validConfirmPassword: null,
    };
  },
  computed: {
    ...mapState(useUserStore, ["isLoading", "resetPasswordErrorMessage"]),
    getPasswordComplexityCorrect() {
      return this.validPassword && this.validConfirmPassword;
    },
    hasError() {
      return this.resetPasswordErrorMessage !== "";
    },
  },
  methods: {
    ...mapActions(useUserStore, ["resetPassword", "getPasswordResetLink"]),
    submitResetPasswordForm() {
      this.resetPassword(this.password, this.userId, this.token);
    },
    validatePasswordComplexity(value) {
      this.validPassword = value;
    },
  },
};
</script>
