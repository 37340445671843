import { $axios } from "@bloglovin/vue-framework";
import { defineStore } from "pinia";
import { useGeneralStore } from "%/stores/general-store";

export const useSubscriptionStore = defineStore("subscription-store", {
  state: () => ({
    subscriptions: [],
    subscriptionBillingHistory: [],
    subscribers: [],
    isLoadingSubscriptions: false,
    isLoadingSubscribers: false,
    isLoadingSubscriptionBillingHistory: false,
    lastSubscriptionId: 0,
    lastSubscriberId: 0,
    lastSubscriptionBillingHistoryId: 0,
    pageSize: 20,
  }),
  getters: {
    hasMoreSubscriptions() {
      return this.lastSubscriptionId !== -1;
    },
    hasMoreSubscriptionBillingHistory() {
      return this.lastSubscriptionBillingHistoryId !== -1;
    },
    hasMoreSubscribers() {
      return this.lastSubscriberId !== -1;
    },
    orgId() {
      return useGeneralStore().orgId;
    },
  },
  actions: {
    fetchSubscriptions() {
      if (this.hasMoreSubscriptions && !this.isLoadingSubscriptions) {
        this.isLoadingSubscriptions = true;
        $axios.get(
          `/subscription/org/${this.orgId}?last_id=${this.lastSubscriptionId}&limit=${this.pageSize}`,
        ).then(response => {
          this.subscriptions = this.subscriptions.concat(response.data.subscriptionsData);
          if (response.data.subscriptionsData.length === 0) {
            this.lastSubscriptionId = -1;
            return;
          }
          this.lastSubscriptionId = response.data.subscriptionsData.at(-1).subscription_plan_id;
          this.isLoadingSubscriptions = false;
        });
      }
    },
    fetchSubscriptionBillingHistory(subscriptionId) {
      if (this.hasMoreSubscriptionBillingHistory && !this.isLoadingSubscriptionBillingHistory) {
        this.isLoadingSubscriptionBillingHistory = true;
        $axios.get(
          `/subscription/${subscriptionId}/billing-history?last_id=${this.lastSubscriptionBillingHistoryId}&limit=${this.pageSize}`,
        ).then(response => {
          this.subscriptionBillingHistory = this.subscriptionBillingHistory.concat(response.data.subscriptionBillingHistoryData);
          if (response.data.subscriptionBillingHistoryData.length === 0) {
            this.lastSubscriptionBillingHistoryId = -1;
            return;
          }
          this.lastSubscriptionBillingHistoryId = response.data.subscriptionBillingHistoryData.at(-1);
        }).finally(() => {
          this.isLoadingSubscriptionBillingHistory = false;
        });
      }
    },

    fetchSubscribers(subscriptionId) {
      if (this.hasMoreSubscribers && !this.isLoadingSubscribers) {
        this.isLoadingSubscribers = true;
        $axios.get(
          `/subscription/${subscriptionId}/subscribers?last_id=${this.lastSubscriberId}&limit=${this.pageSize}`,
        ).then(response => {
          this.subscribers = this.subscribers.concat(response.data.subscribersData);
          if (response.data.subscribersData.length === 0) {
            this.lastSubscriberId = -1;
            return;
          }
          this.lastSubscriberId = response.data.subscribersData.at(-1).subscriber_id;
        }).finally(() => {
          this.isLoadingSubscribers = false;
        });
      }
    },
    createSubscriptionProduct(subscriptionData) {
      $axios.post(`/subscriptions/org/${this.orgId}`, {
        subscriptionData,
      }).then(response => {
        this.subscriptions = this.subscriptions.concat(response);
      });
    },
  },
});
