// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-49e113ec] .modal-body {
  height: 50vh;
  overflow-y: auto;
}
[data-v-49e113ec] .card-metrics {
  row-gap: var(--49e113ec-\\\$getStyles\\.metricsGap);
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/components/TemplateDetails.vue","webpack://./TemplateDetails.vue"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,gBAAA;ACDF;ADGA;EACE,gDAAA;ACAF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n:deep(.modal-body) {\n  height: 50vh;\n  overflow-y: auto;\n}\n:deep(.card-metrics){\n  row-gap: v-bind(\"$getStyles.metricsGap\");\n}\n",":deep(.modal-body) {\n  height: 50vh;\n  overflow-y: auto;\n}\n\n:deep(.card-metrics) {\n  row-gap: v-bind(\"$getStyles.metricsGap\");\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
