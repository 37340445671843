<template>
  <iModal
    v-if="show"
    :close-on-backdrop="false"
    :close-on-escape="false"
    :close-on-secondary="false"
    :show-footer="false"
    title="Story Variants"
    @close="$emit('close')"
  >
    <template #body>
      <iColumn>
        <iRow align="center" vertical-align="middle" wrap="nowrap">
          <iColumn>
            <iLink :href="`/stories/editor?post_id=${parentData.post_id || parentId || postId}`">
              <iSubHeading>{{ parentData.post_title || postTitle || "No title" }}</iSubHeading>
            </iLink>
          </iColumn>
          <iButton variant="secondary" width="hug" @click="showCreateVariantsModal = true;">
            Create Variant
          </iButton>
        </iRow>
      </iColumn>
      <SmartFeed
        :pinia-store="useOrgPostVariantFeedStore"
        default-smart-feed-layout="table"
        default-entity-layout="tableRow"
      />
    </template>
  </iModal>
  <CreateVariantsModal
    :post-id="postId"
    :post-title="postTitle"
    :show="showCreateVariantsModal"
    @close="showCreateVariantsModal = false"
  />
</template>

<script>
import SmartFeed from "SmartFeed/components/SmartFeed";
import { useOrgPostVariantFeedStore } from "SmartFeed/stores/feed/org-post-variant-feed-store";
import CreateVariantsModal from "@/components/publication/CreateVariantsModal";
import { mapState } from "pinia";

export default {
  name: "ShowVariantsModal",
  components: { CreateVariantsModal, SmartFeed },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    postTitle: {
      type: String,
      required: false,
      default: "No title",
    },
    postId: {
      type: Number,
      required: true,
    },
    parentId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  emits: ["close"],
  data() {
    return {
      showCreateVariantsModal: false,
    };
  },
  computed: {
    ...mapState(useOrgPostVariantFeedStore, ["metadata"]),
    parentData() {
      return this.metadata.parentData;
    },
  },
  watch: {
    show: {
      handler(newValue) {
        if (newValue) {
          this.metadata.parentId = this.parentId || this.postId;
        } else {
          useOrgPostVariantFeedStore().$reset();
        }
      },
    },
  },
  methods: { useOrgPostVariantFeedStore },
};
</script>

<style lang="scss" scoped>

</style>
