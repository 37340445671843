<template>
  <PageWrapper :active-section="SECTION_PUBLICATIONS" :active-page="PAGE_PUBLICATION_POSTS">
    <StoryEditorComponent />
    <iRow>
      <iButton
        variant="primary"
        width="hug"
        @click="publishToNetwork('Twitter')"
      >
        Publish to X
      </iButton>
      <iButton
        variant="primary"
        width="hug"
        @click="publishToNetwork('LinkedIn')"
      >
        Publish to LinkedIn
      </iButton>
    </iRow>
    <iModal
      v-if="showEditPostConfirmation"
      title="Edit Post"
      :width="600"
      :close-on-backdrop="false"
      :close-on-escape="false"
      :close-on-secondary="false"
      :primary-action-button-loading="updatePostIsLoading"
      primary-action-button-label="Continue"
      @close="closeEditPostConfirmation"
      @click:primary="goToEditPost"
      @click:secondary="closeEditPostConfirmation"
    >
      <template #body>
        <iParagraph>
          Your post is currently live. If you want to continue editing, your post will be changed to draft.
        </iParagraph>
      </template>
    </iModal>
  </PageWrapper>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";
import { PAGE_PUBLICATION_POSTS, SECTION_PUBLICATIONS } from "@/constants/nav-constants";
import PageWrapper from "@/components/PageWrapper.vue";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useSocialNetworksStore } from "@/stores/social-networks-store";
import { useGeneralStore } from "%/stores/general-store";
import StoryEditorComponent from "@/components/publication/StoryEditorComponent";
dayjs.extend(relativeTime);

export default {
  name: "ViewPublicationPost",
  components: {
    StoryEditorComponent,
    PageWrapper,
  },
  data() {
    return {
      PAGE_PUBLICATION_POSTS,
      SECTION_PUBLICATIONS,
      showEditPostConfirmation: false,
      initialLoad: 0,
    };
  },
  computed: {
    ...mapState(useGeneralStore, ["orgId"]),
    ...mapWritableState(usePublicationStore, ["post", "updatePostIsLoading"]),
  },
  methods: {
    ...mapActions(useSocialNetworksStore, ["postMessage"]),
    publishToNetwork(socialNetwork) {
      this.postMessage(this.orgId, socialNetwork, this.post.post_id);
    },
  },
};
</script>

<style scoped lang="scss">

</style>
