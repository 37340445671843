<template>
  <iModal
    width="hug"
    :visible="visible"
    title="New Subscription Plan"
    primary-action-button-label="Add Plan"
    @close="closeModal"
    @click:primary="addSubscriptionPlan"
    @click:secondary="closeModal"
  >
    <template #body>
      <iColumn width="fill">
        <iTextInput v-model="name" label="Name" />
        <iTextArea v-model="description" label="Description" />
        <iSelect
          id="billing-frequency"
          v-model="billingFrequency"
          label="Billing Frequency"
          :items="subscriptionTypes"
          value-field="name"
          text-field="name"
        />
        <iTextInput v-model="price" type="number" label="Price" />
        <!-- <iSelect
          id="currency-code"
          v-model="currency_code"
          label="Currency"
          :items="currencyCodes"
          value-field="name"
          text-field="symbol"
        /> -->
        <!-- <iTextInput v-model="exclusivity_window_days" type="number" label="Exclusivity window" /> -->
      </iColumn>
    </template>
  </iModal>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useConfigurationStore } from "@/stores/configuration-store";
import { usePublicationStore } from "@/stores/publication-store";
import { MAPPED_CURRENCY_CODES } from "@/constants/currency-constants";

export default {
  name: "CreateSubscriptionModal",
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["close"],
  data() {
    return {
      name: "",
      description: "",
      price: 0,
      billingFrequency: { name: "weekly", id: "weekly" },
      exclusivityWindowDays: 0,
      currencyCode: MAPPED_CURRENCY_CODES[0],
    };
  },
  computed: {
    ...mapState(usePublicationStore, ["validBillingFrequencies"]),
    subscriptionTypes() {
      return this.validBillingFrequencies.map(key => ({ name: key, id: key }));
    },
  },
  methods: {
    ...mapActions(useConfigurationStore, ["createSubscriptionPlan"]),
    closeModal(event) {
      this.$emit("close", event);
    },
    async addSubscriptionPlan() {
      this.createSubscriptionPlan(
        this.name,
        this.description,
        this.billingFrequency.name,
        parseFloat(this.price),
        this.currencyCode.key,
        5,
        // eslint-disable-next-line @stylistic/js/comma-dangle
        parseInt(this.exclusivityWindowDays)
      );
      this.closeModal();
    },
  },
};
</script>
