<template>
  <iColumn class="digital-products">
    <iRow vertical-align="middle">
      <iSubHeading>
        Digital Products
      </iSubHeading>
      <iColumn width="hug">
        <iButton
          v-if="!isCreateDigitalProductModalOpen"
          variant="secondary"
          @click="openCreateDigitalProductModal"
        >
          Add Digital Product
        </iButton>
      </iColumn>
    </iRow>
    <CreateDigitalProductModal :visible="isCreateDigitalProductModalOpen" @close="closeCreateDigitalProductModal" />
  </iColumn>
</template>

<script>
import { mapState } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";
import CreateDigitalProductModal from "@/components/publication/CreateDigitalProductModal.vue";
export default {
  name: "MyPublicationDigitalProducts",
  components: {
    CreateDigitalProductModal,
  },
  props: {
  },
  data() {
    return {
      subscriberAreaEnabled: false,
      isCreateDigitalProductModalOpen: false,
    };
  },
  computed: {
    ...mapState(usePublicationStore, ["availableDigitalProducts"]),
  },
  methods: {
    openCreateDigitalProductModal() {
      this.isCreateDigitalProductModalOpen = true;
    },
    closeCreateDigitalProductModal() {
      this.isCreateDigitalProductModalOpen = false;
    },
  },
};
</script>

<style scoped>
</style>
