<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="publication.name"
    :breadcrumbs="publicationBreadCrumbs"
    page-name="Overview"
    align="left"
    width="full"
  >
    <ProfileWrapper
      :splash-image="publicationSplashImage"
      :logo-image="publicationLogo"
      :is-loading-splash="loadingUploadSplashImage"
      can-upload-logo
      can-upload-splash
      can-edit-title
      can-edit-details
      has-menu
      :wrap-menu="false"
      :is-loading-logo="loadingUploadPublicationLogo"
      :title="publicationName"
      :details="publicationDescription || 'Click here to add a description for your publication'"
      @click:save-title="savePublicationName"
      @click:save-details="savePublicationDescription"
      @click:splash-image="triggerUploadSplash"
      @click:logo-image="triggerUploadLogo"
    >
      <iSpace :height="10" />
      <template #menu>
        <iDropdown :show-dropdown="isDropdownOpen" position="right" @close="closeDropdown">
          <template #trigger>
            <iIcon icon="dots-vertical" @click="openDropdown" />
          </template>
          <template #overlay>
            <iButton v-if="publication.status !== 'active'" variant="tertiary" @click="updateStatus('active')">
              Activate Publication
            </iButton>
            <iButton v-if="publication.status !== 'draft'" variant="tertiary" @click="updateStatus('draft')">
              Set As Draft
            </iButton>
            <iButton v-if="publication.status !== 'inactive'" variant="tertiary" @click="updateStatus('inactive')">
              De-activate Publication
            </iButton>
          </template>
        </iDropdown>
      </template>
      <iStyledCard title="Statistics">
        <template #description>
          <iText>{{ totalUsers }} {{ totalUsers === 1 ? 'user' : 'users' }}</iText>
          <iText>{{ totalSubscribers }} {{ totalSubscribers === 1 ? 'subscriber' : 'subscribers' }}</iText>
        </template>
      </iStyledCard>
      <iStyledCard :subtitle="'Status: ' + mappedPublicationStatus" title="Domain & Links">
        <template #description>
          <iColumn>
            <iText>
              Public Profile:
            </iText>
            <iLink :href="publicProfileUrl">
              {{ publicProfileUrl }}
            </iLink>
            <iSpace :height="1" />
            <iText>
              Production:
            </iText>
            <iLink v-if="hasDomain" target="_blank" :href="domainUrl">
              {{ domainUrl }}
            </iLink>
            <iLink v-if="!hasDomain" target="_blank" :href="internalDomain">
              {{ internalDomain }}
            </iLink>
            <iLink
              v-if="hasDomain"
              color="subtle"
              target="_blank"
              :href="internalDomain"
            >
              {{ internalDomain }}
            </iLink>
            <iSpace :height="1" />
            <iText>
              Staging:
            </iText>
            <iLink
              v-if="hasDomain"
              target="_blank"
              :href="domainStaging"
            >
              {{ domainStaging }}
            </iLink>
            <iLink
              v-if="!hasDomain"
              target="_blank"
              :href="internalStagingDomain"
            >
              {{ internalStagingDomain }}
            </iLink>
            <iLink
              v-if="hasDomain"
              color="subtle"
              target="_blank"
              :href="internalStagingDomain"
            >
              {{ internalStagingDomain }}
            </iLink>
          </iColumn>
        </template>
      </iStyledCard>
    </ProfileWrapper>
    <iUploader ref="logo-uploader" @upload-file="uploadLogo" />
    <iUploader ref="splash-uploader" @upload-file="uploadSplash" />
  </PageWrapper>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";
import PageWrapper from "@/components/PageWrapper";
import {
  PAGE_PUBLICATION_DASHBOARD,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";
import ProfileWrapper from "@/components/publication/ProfileWrapper";

export default {
  name: "ViewPublicationDetails",
  components: {
    ProfileWrapper,
    PageWrapper,
  },
  data() {
    return {
      PAGE_PUBLICATION_DASHBOARD,
      SECTION_PUBLICATIONS,
      isDropdownOpen: false,
    };
  },
  computed: {
    ...mapState(usePublicationStore, [
      "publication",
      "publicationId",
      "orgId",
      "publicationLogo",
      "publicationSplashImage",
      "publicationName",
      "publicationDescription",
      "isLoadingSavePublicationName",
      "isLoadingSavePublicationDescription",
      "loadingUploadPublicationLogo",
      "loadingUploadSplashImage",
    ]),
    domain() {
      return this.publication.domain;
    },
    publicationBreadCrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
      ];
    },
    totalUsers() {
      return this.publication.metrics.filter(metric => metric.id === "users")?.[0]?.value || 0;
    },
    mappedPublicationStatus() {
      switch (this.publication.status) {
        case "active":
          return "Live";
        case "draft":
          return "Under Construction";
        default:
          return "Offline";
      }
    },
    totalSubscribers() {
      return this.publication.metrics.filter(metric => metric.id === "subscribers")?.[0]?.value || 0;
    },
    domainStaging() {
      if (this.domain) {
        if (this.domain.startsWith("http", 0)) {
          return this.domain;
        }
        return "https://" + this.domain + "/staging";
      }
      return "No domain set";
    },
    domainUrl() {
      if (this.domain) {
        if (this.domain.startsWith("http", 0)) {
          return this.domain;
        }
        return "https://" + this.domain;
      }
      return "No domain set";
    },
    internalDomain() {
      return `${window.location.origin}/my-publication/${this.publicationId}`;
    },
    internalStagingDomain() {
      return `${window.location.origin}/my-publication/staging/${this.publicationId}`;
    },
    publicProfileUrl() {
      return `${window.location.origin}/community/publication/${this.publicationId}/profile`;
    },
    hasDomain() {
      return this.internalDomain !== this.domain;
    },
  },
  methods: {
    ...mapActions(usePublicationStore, ["uploadPublicationLogo", "uploadSplashImage", "savePublicationName", "savePublicationDescription", "updatePublicationStatus"]),
    triggerEditPublicationName() {
      this.isEditNameOpen = true;
    },
    triggerEditPublicationDescription() {
      this.isEditNameOpen = true;
    },
    triggerUploadLogo() {
      this.$refs["logo-uploader"].$refs.file.click();
    },
    triggerUploadSplash() {
      this.$refs["splash-uploader"].$refs.file.click();
    },
    uploadLogo(file) {
      this.uploadPublicationLogo(file);
    },
    uploadSplash(file) {
      this.uploadSplashImage(file);
    },
    openDropdown() {
      this.isDropdownOpen = true;
    },
    closeDropdown() {
      this.isDropdownOpen = false;
    },
    updateStatus(status) {
      this.updatePublicationStatus(status);
      this.closeDropdown();
    },
  },
  styleGuide: () => ({
    logoBorderWidth: { "size.border": "thick" },
    logoBorderColor: { "color.border": "dark" },
    logoBackground: { "color.background": "light" },
    menuLinkHorizontalPadding: { "size.spacing": "standard" },
  }),
};
</script>

<style scoped lang="scss">
.publication-header-wrapper{
  position: relative;
}

.publication-header {
  position: relative;
  bottom: 30px;
}

.publication-header-logo {
  border: v-bind("$getStyles.logoBorderWidth") solid v-bind("$getStyles.logoBorderColor");
  background: v-bind("$getStyles.logoBackground")
}
:deep(.button-text-link){
  padding-left: v-bind("$getStyles.menuLinkHorizontalPadding") !important;
  padding-right: v-bind("$getStyles.menuLinkHorizontalPadding") !important;
}
</style>
