<template>
  <PageWrapper
    :active-section="SECTION_BILLING"
    :active-page="PAGE_ACTIVE_SUBSCRIPTIONS"
    title="Active Subscriptions"
    :breadcrumbs="breadcrumbs"
    page-name="Active Subscriptions"
    align="left"
    width="full"
  >
    <iColumn gap="extraLarge">
      <iRow gap="standard">
        <iSubHeading variant="subtle">
          Active Subscriptions
        </iSubHeading>
      </iRow>
    </iColumn>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import {
  PAGE_ACTIVE_SUBSCRIPTIONS,
  SECTION_BILLING,
} from "@/constants/nav-constants";
import { useGeneralStore } from "%/stores/general-store";
import { mapState } from "pinia";

export default {
  name: "ActiveSubscriptions",
  components: {
    PageWrapper,
  },
  data() {
    return {
      SECTION_BILLING,
      PAGE_ACTIVE_SUBSCRIPTIONS,
    };
  },
  computed: {
    ...mapState(useGeneralStore, ["settingsBreadcrumbs"]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: "/billing", label: "Billing" },
      ];
    },
  },
};
</script>
